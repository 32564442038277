// Fonts
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&amp;display=swap');
//@import '~font-awesome';

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
@import "~select2";
@import './theme.css';

